<template>
  <div>
    <b-row class="justify-content-between align-items-center">
      <b-col md="6">
        <div class="img">
          <img src="/assets/images/logo-washliyah-white.png" alt="" width="150">
        </div>
        <h3>PUSAT DATA KEANGGOTAAN</h3>
        <h1>AL JAM’IYATUL WASHLIYAH</h1>
        <h1>SUMATERA UTARA</h1>
        <form @submit.prevent="doCekKta">
          <div class="input-group mb-3">
            <input type="text" v-model="model.number_id" class="form-control capitalize" placeholder="Ketikkan Nomor Anggota" aria-label="Username" aria-describedby="basic-addon1">
            <b-button type="submit" >Cek KTA</b-button>
           <!-- <font-awesome-icon icon="search" class="fa-2x form-search-icon" /> -->
          </div>
        </form>
      </b-col>
      <b-col md="6">
        <div class="card" style="width: 25rem; border-radius:15px; margin-left: auto;">
          <div class="card-body" style="padding:2rem;">
              <h5 class="card-title text-left"><strong>Masuk</strong></h5>
              <p>Silahkan masuk ke akun Anda.</p>
              <div class="card-text mt-5">
                  <form @submit.prevent="onSubmit">
                      <div class="form-group">
                          <input type="text" ref="email" class="form-control" placeholder="Ketikkan nama akun"  v-model="model.username" required>
                          <!-- <p class="text-danger">{{messageValidation.email}}</p> -->
                      </div>
                      <div class="form-group">
                          <input type="password" ref="password" class="form-control" placeholder="Ketikkan kata sandi" v-model="model.password" required>
                          <!-- <p class="text-danger">{{messageValidation.password}}</p> -->
                      </div>
                      <div class="form-group mb-0 mt-5">
                          <button type="submit" class="btn btn-success btn-login" style="width:100%;"> Masuk <font-awesome-icon v-if="isLoading" icon="spinner" spin /><font-awesome-icon v-else icon="angle-double-right" /></button>
                      </div>
                  </form>
              </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      model: {
        username: null,
        password: null,
        number_id: null
      }
    }
  },
  methods: {
    doCekKta () {
      window.location.href = '#/kta/' + this.model.number_id
    },
    onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      this.$store.dispatch('account/login', {
        username: this.model.username,
        password: this.model.password
      }).then(() => {
        // this.$store.dispatch('notification/welcome', this.$store.getters['account/accountData'].firstName)
        this.isLoading = false
        this.$router.push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/').then(() => {
          // location.reload()
        })
      }).catch(error => {
        this.isLoading = false
        this.$store.dispatch('notification/error', error)
        // this.$store.dispatch('notification/error', 'Kombinasi email & password anda salah')
      })
    }
  }
}
</script>