<template>

    <div>
    <b-card>
        <template v-if="isLoading">
            <div class="text-center m-5 d-flex justify-content-center">
                <b-spinner variant="success" style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
        </template>
        <template v-else>
            <b-row>
                <b-col md="12">
                    <b-row>
                        <b-col md="6">
                          <h3 class="page-title mb-0 text-dark"> Info Data Anggota</h3>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6"> 
                    <b-row class="mt-3" v-if="model.number_id !== null">
                        <b-col md="4">
                            <strong class="text-uppercase">Nomor Anggota</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.number_id}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Nik</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.nik}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Name</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.name}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Gender</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.gender}}</span>
                        </b-col>
                    </b-row>
                    <h4 class="mt-5"><strong class="text-uppercase">Kelahiran</strong></h4>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Tempat Lahir</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.place_birth}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Tanggal Lahir</strong>
                        </b-col>
                        <b-col md="8">
                            {{getDate(model.date_birth)}}
                        </b-col>
                    </b-row>
                    <h4 class="mt-5"><strong class="text-uppercase">Alamat</strong></h4>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Kota/Kabupaten</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.regency.name}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Kecamatan</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.district.name}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Kelurahan/Desa</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.village.name}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Detail Alamat</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.address}}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="6">
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Nomor Kontak</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.number_phone}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Pekerjaan</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.job}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Genealogi</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.genealogy}}</span>
                        </b-col>
                    </b-row>
                    <h4 class="mt-5"><strong class="text-uppercase">Unggah</strong></h4>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Foto Diri</strong>
                        </b-col>
                        <b-col md="8">
                            <img :src="model.url_photo_profile" alt="">
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Foto ktp</strong>
                        </b-col>
                        <b-col md="8">
                            <img :src="model.url_photo_ktp" alt="" width="300">
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <template v-if="model.is_verified">
                <h4 class="mt-5"><strong class="text-uppercase">File KTA</strong></h4>
                <b-row class=" aligment-middle">
                    <b-col md="6" class="border p-3">
                        <!-- <div id="my-kta">
                            <b-img src="/assets/images/kta_washliyah.png" style="max-width: 100%"></b-img>
                            <div style="position: absolute; top: 43%; left: 10.3%;">
                                <img id="my-photo" :src="model.url_photo_profile" alt="" style="max-width: 89%;">
                            </div>
                            <div class="description-kta" style="position: absolute; top: 44.5%; left: 48%;">
                                <p class="text-uppercase">{{model.number_id}}</p>
                                <p class="text-uppercase">{{model.name}}</p>
                                <p class="text-uppercase">{{model.place_birth}}</p>
                                <p class="text-uppercase">{{getDate(model.date_birth)}}</p>
                                <p class="text-uppercase">{{model.address}}, Kel. {{model.village.name}}, Kec. {{model.district.name}}, {{model.regency.name}}, Sumatera Utara</p>
                            </div>
                        </div> -->
                        
                        <iframe width="100%" height="400" :src="apiUrl.pdf + '/' + model.id + '/preview'" frameborder="0"></iframe>
                        <div class="text-center mt-3">
                          <!-- <b-button variant="success" @click="doDownloadKTA(model.id)" class="mr-3">
                            <font-awesome-icon icon="download" />
                            Download ke Gambar KTA
                          </b-button> -->
                          <b-button variant="info" @click="doExportKTA(model.id)">
                            <font-awesome-icon icon="download" />
                            Download ke PDF KTA
                          </b-button>
                        </div>
                    </b-col>
                    <b-col md="6" class="border text-center p-3">
                        <img :src="model.url_qr_code" alt="" width="50%" style="display: flex; margin: 0 auto; margin-top: 8%;">
                        <b-button variant="success" @click="doDownloadQRCode(model.id)" class="mr-3" style="position: absolute; bottom: 20px; left: 36%;">
                            <font-awesome-icon icon="download" />
                            Download QR Code
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </template>
    </b-card>
    </div>
</template>
<style>
  .full-member-background .description-kta p {
      font-family: caviar-bold;
      padding-bottom: 0;
      margin-bottom: -3px;
      font-size: 17.5px;
      text-transform: uppercase;
  }
</style>
<script>
    import domtoimage from 'dom-to-image';
    export default {
        data () {
            return {
                isLoading: true,
                apiUrl: {
                    member: process.env.VUE_APP_API_URL + 'member-info',
                    pdf: process.env.VUE_APP_API_BASE_URL + 'kta'
                },
                model: {
                    id: null,
                    number_id: null,
                    nik: null,
                    name: null,
                    place_birth: null,
                    date_birth: null,
                    regency_id: null,
                    district_id: null,
                    village: null,
                    regency: null,
                    district: null,
                    address: null,
                    number_phone: null,
                    job: null,
                    genealogy: null,
                    url_photo_ktp: null,
                    url_photo_profile: null,
                    url_qr_code: null,
                    is_verified: null
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            getDetail() {
                this.isLoading = true;
                this.$http.get(this.apiUrl.member + "/" + this.$route.params.id)
                .then((response) => {
                    this.model.id = response.data.id
                    this.model.number_id = response.data.number_id
                    this.model.nik = response.data.nik
                    this.model.name = response.data.name
                    this.model.place_birth = response.data.place_birth
                    this.model.date_birth = response.data.date_birth
                    this.model.district = response.data.village.district
                    this.model.regency = response.data.village.district.regency
                    this.model.village = response.data.village
                    this.model.address = response.data.address
                    this.model.number_phone = response.data.number_phone
                    this.model.job = response.data.job
                    this.model.is_verified = response.data.is_verified
                    this.model.gender = response.data.gender
                    this.model.genealogy = response.data.genealogy
                    this.model.url_photo_ktp = response.data.files_photo_ktp['']
                    this.model.url_photo_profile = response.data.files_photo_diri['']
                    this.model.url_qr_code = process.env.VUE_APP_API_BASE_URL_IMAGE + '/' + response.data.url_qr_code
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$store.dispatch("notification/error", error);
                    if (
                        typeof error !== "undefined" &&
                        typeof error.response !== "undefined" &&
                        typeof error.response.status !== "undefined" &&
                        error.response.status === 404
                    ) {
                        // this.$router.push("/notfound");
                    }
                });
            },
            getDate(value) {
                return this.$moment(value).format("DD-MM-YYYY");
            },
            doDownloadKTA (id) {
                window.location.href = process.env.VUE_APP_API_BASE_URL_IMAGE + '/download-kta/' + id
            },
            doExportKTA (id) {
                window.location.href = process.env.VUE_APP_API_BASE_URL_IMAGE + '/kta/' + id + '/export'
            },
            getBase64Image() {
            },
            getBase64FromUrl : async (url) => {
                const data = await fetch(url);
                const blob = await data.blob();
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob); 
                    reader.onloadend = () => {
                    const base64data = reader.result;   
                    resolve(base64data);
                    }
                });
            },
            doDownloadQRCode (id) {
                window.location.href = process.env.VUE_APP_API_BASE_URL_IMAGE + '/download-qrcode/' + id
                // domtoimage.toBlob(document.getElementById('img-qr-code'))
                // .then(function (blob) {
                //     window.saveAs(blob, 'qr-code.png');
                // });
            },
        }
    }
</script>